<template>
  <section>
    <CLoading :loading="loading" />
    <q-table
      bordered
      flat
      dense
      title="Forms"
      :rows="forms"
      row-key="title"
      :rows-per-page-options="[10]"
      separator="cell"
      :no-data-label="$t('messages.no_data_available')"
      class="text-grey-8"
    >
      <template v-slot:header="props">
        <q-tr>
          <q-th key="action" rowspan="2" class="text-left" style="width: 140px"></q-th>
          <q-th :props="props" key="title" rowspan="2" style="width: 340px">
            <span class="text-bold">{{ $t('label.form.form_name') }} </span>
          </q-th>
          <q-th :props="props" key="details" rowspan="2">
            <span class="text-bold">{{ $t('label.form.details') }} </span>
          </q-th>
          <q-th :props="props" key="applied_campain" rowspan="2" class="text-center">
            <span class="text-bold">{{ $t('label.form.applied_campaign') }} </span>
          </q-th>
        </q-tr>
      </template>
      <template v-slot:body="props">
        <q-tr :props="props" :index="props.rowKey">
          <q-td key="action" :props="props.index">
            <q-btn size="sm" flat round icon="edit" @click="onEdit(props.row)" class="q-mr-sm" />
            <q-btn size="sm" flat round icon="content_copy" @click="onCopy(props.row)" class="q-mr-sm" />
            <q-btn size="sm" flat round color="red" icon="delete" @click="onDelete(props.row)" />
          </q-td>
          <q-td key="title" :props="props">
            {{ props.row.title }} <TagContent :tagValue="props.row.display_id"></TagContent>
          </q-td>
          <q-td key="details" :props="props" style="text-wrap: auto">
            <q-chip color="primary" text-color="white" v-for="(item, index) in props.row.details" :key="index">
              {{ optionTypeName(item.option_type) }}
            </q-chip>
          </q-td>
          <q-td key="applied_campain" :props="props" text-center>
            <q-chip color="primary" text-color="white">
              {{ numberWithCommas(props.row.applied_campain) }}
            </q-chip>
          </q-td>
        </q-tr>
      </template>
      <template v-slot:top>
        <h2 class="text-h6 text-black">{{ $t('label.form.form_list') }}</h2>
        <q-space />
        <q-btn no-caps class="btn-save" @click="onAdd()" :label="$t('add_new')" />
      </template>
    </q-table>
  </section>
  <FormFormModal
    v-if="modalVisible"
    :modalVisible="modalVisible"
    :data="selectedForm"
    @update:closeModal="onCloseModal"
  ></FormFormModal>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import CLoading from '@/components/common/ui/CLoading.vue'
import { Prop, Watch } from 'vue-property-decorator'
import { IForm, IValidRequest } from '@/utils/types'
import { ACTION_APP, ACTION_FORM } from '@/store/actions'
import cloneDeep from 'lodash/cloneDeep'
import { Formater } from '@/utils/formater'
import TagContent from '@/components/common/ui/TagContent.vue'
import FormFormModal from '@/components/form/FormFormModal.vue'
import { maska } from 'maska'
import { FORM_FIELD_TYPE } from '@/utils/constants'

@Options({
  components: { FormFormModal, TagContent, CLoading },
  directives: { maska },
})
export default class Forms extends Vue {
  @Prop()
  appId!: string

  modalVisible = false
  loading = true

  selectedForm: IForm = {}

  get forms(): IForm[] {
    return this.$store.getters.forms
  }

  @Watch('appId', { immediate: true })
  async appIdChanged() {
    if (!this.appId) {
      return
    }

    await this.$store.dispatch(ACTION_FORM.LOAD_ITEMS, {
      app_id: this.appId,
      is_include_data: true,
    })

    this.loading = false
  }

  onAdd() {
    this.selectedForm = {
      app_id: this.appId,
    }

    this.modalVisible = true
  }

  onCopy(data: IForm) {
    const formCopy = cloneDeep(data)
    formCopy._id = ''
    formCopy.title = formCopy.title + ' Copy'
    this.selectedForm = formCopy
    this.modalVisible = true
  }

  onEdit(data: IForm) {
    this.selectedForm = data
    this.modalVisible = true
  }

  async onDelete(form: IForm) {
    this.$q
      .dialog({
        title: this.$t('confirm'),
        message: this.$t('are_you_sure_you_want_to_delete'),
        cancel: true,
        persistent: true,
      })
      .onOk(async () => {
        const exists = await this.checkItemExists(form)
        if (!exists) {
          const success = await this.$store.dispatch(ACTION_FORM.DELETE, form._id)
          if (success) {
            this.$q.notify({
              message: this.$t('messages.deleted'),
              color: 'positive',
            })
          }
        }
      })
  }

  onCloseModal() {
    this.modalVisible = false
    this.selectedForm = {}
  }

  numberWithCommas(num) {
    return Formater.numberFormat(num)
  }

  optionTypeName(option_type) {
    if (option_type === FORM_FIELD_TYPE.NAME_KANJI) {
      return this.$t('label.common.form.form_field_type_name_kanji')
    } else if (option_type === FORM_FIELD_TYPE.NAME_HIRA) {
      return this.$t('label.common.form.form_field_type_name_hira')
    } else if (option_type === FORM_FIELD_TYPE.NAME_KANA) {
      return this.$t('label.common.form.form_field_type_name_kana')
    } else if (option_type === FORM_FIELD_TYPE.PHONE_NUMBER_DASH) {
      return this.$t('label.common.form.form_field_type_phone_number_dash')
    } else if (option_type === FORM_FIELD_TYPE.PHONE_NUMBER_NO_DASH) {
      return this.$t('label.common.form.form_field_type_phone_number_no_dash')
    } else if (option_type === FORM_FIELD_TYPE.ADDRESS) {
      return this.$t('label.common.form.form_field_type_address')
    } else if (option_type === FORM_FIELD_TYPE.POSTCODE_DASH) {
      return this.$t('label.common.form.form_field_type_postcode_dash')
    } else if (option_type === FORM_FIELD_TYPE.POSTCODE_NO_DASH) {
      return this.$t('label.common.form.form_field_type_postcode_no_dash')
    } else if (option_type === FORM_FIELD_TYPE.EMAIL) {
      return this.$t('label.common.form.form_field_type_email')
    }
    return this.$t('label.common.form.form_field_type_freetext')
  }

  async checkItemExists(form: IForm) {
    const { dispatch } = this.$store
    const params: IValidRequest = {
      app_id: this.appId,
      initial_id: '',
      push_id: '',
      card_id: form._id ?? '',
      card_type: 'form',
      total_messages: 0,
      campaign: {},
    }
    const resp = await dispatch(ACTION_APP.CHECK_ITEM_EXISTS, params)

    if (resp.campaign_title) {
      this.$q.dialog({
        title: this.$t('error'),
        message: this.$t('messages.item_exists_in_campaign', { names: resp.campaign_title.toString() }),
        persistent: true,
        html: true,
      })
      return true
    }
    return false
  }
}
</script>

<style scoped lang="scss">
</style>
