<template>
  <section>
    <CLoading :loading="loading" />
    <q-table
      bordered
      dense
      flat
      title="Treats"
      ref="messagesList"
      :rows="goals"
      row-key="title"
      :rows-per-page-options="[10]"
      separator="cell"
      :no-data-label="$t('messages.no_data_available')"
      class="text-grey-8"
    >
      <template v-slot:header="props">
        <q-tr>
          <q-th key="action" rowspan="2" class="text-left" style="width: 100px"></q-th>
          <q-th :props="props" key="title" rowspan="2"
            ><span class="text-bold">{{ $t('label.goal.goal_name') }}</span></q-th
          >
          <q-th :props="props" key="applied_campain" rowspan="2" class="text-center">
            <span class="text-bold">{{ $t('label.goal.applied_campaign') }} </span>
          </q-th>
        </q-tr>
      </template>
      <template v-slot:body="props">
        <q-tr :props="props" :index="props.rowKey">
          <q-td key="action" :props="props.index">
            <q-btn size="sm" flat round icon="edit" @click="onEdit(props.row)" class="q-mr-sm" />
            <q-btn size="sm" flat round icon="content_copy" @click="onCopy(props.row)" class="q-mr-sm" />
            <q-btn size="sm" flat round color="red" icon="delete" @click="onDelete(props.row)" />
          </q-td>
          <q-td key="title" :props="props">
            {{ props.row.title }}<TagContent :tagValue="props.row.display_id"></TagContent>
          </q-td>
          <q-td key="applied_campain" :props="props">
            <q-chip color="primary" text-color="white">
              {{ numberWithCommas(props.row.applied_campain) }}
            </q-chip>
          </q-td>
        </q-tr>
      </template>

      <template v-slot:top>
        <h2 class="text-h6 text-black">{{ $t('label.goal.goal_list') }}</h2>
        <q-space />
        <q-btn no-caps class="btn-save" @click="onAdd()" :label="$t('add_new')" />
      </template>
    </q-table>
  </section>
  <GoalFormModal
    v-if="modalVisible"
    :modalVisible="modalVisible"
    :data="selectedGoal"
    @update:closeModal="onCloseModal"
  />
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
import { maska } from 'maska'
import { IGoal, IValidRequest } from '@/utils/types'
import { ACTION_APP, ACTION_GOAL } from '@/store/actions'
import { calcOrderingDragend } from '@/utils/helpers'
import GoalFormModal from './GoalFormModal.vue'
import cloneDeep from 'lodash/cloneDeep'
import { Formater } from '@/utils/formater'
import CLoading from '@/components/common/ui/CLoading.vue'
import TagContent from '@/components/common/ui/TagContent.vue'

@Options({
  components: {
    GoalFormModal,
    CLoading,
    TagContent,
  },
  directives: { maska },
})
export default class Goals extends Vue {
  @Prop()
  appId!: string

  dragging = false
  modalVisible = false
  selectedGoal: IGoal = {}
  loading = true

  get goals(): IGoal[] {
    return this.$store.getters.goals || []
  }

  @Watch('appId', { immediate: true })
  async appIdChanged() {
    if (!this.appId) {
      return
    }

    await this.$store.dispatch(ACTION_GOAL.LOAD_ITEMS, {
      app_id: this.appId,
      is_include_data: true,
    })

    this.loading = false
  }

  onAdd() {
    this.selectedGoal = {
      app_id: this.appId,
    }

    this.modalVisible = true
  }

  onCopy(data: IGoal) {
    const goalCopy = cloneDeep(data)
    goalCopy._id = ''
    goalCopy.title = goalCopy.title + ' Copy'
    this.selectedGoal = goalCopy
    this.modalVisible = true
  }

  onEdit(data: IGoal) {
    this.selectedGoal = data
    this.modalVisible = true
  }

  async onDelete(goal: IGoal) {
    this.$q
      .dialog({
        title: this.$t('confirm'),
        message: this.$t('are_you_sure_you_want_to_delete'),
        cancel: true,
        persistent: true,
      })
      .onOk(async () => {
        const exists = await this.checkItemExists(goal)
        if (!exists) {
          const success = await this.$store.dispatch(ACTION_GOAL.DELETE, goal._id)
          if (success) {
            this.$q.notify({
              message: this.$t('messages.deleted'),
              color: 'positive',
            })
          }
        }
      })
  }

  onCloseModal() {
    this.modalVisible = false
    this.selectedGoal = {}
  }

  // eslint-disable-next-line
  async onDragEnd(evt: any, finalList: IGoal[]) {
    if (!evt.moved) {
      return
    }
    const record = evt.moved.element
    const finalPosition = calcOrderingDragend(record, finalList)

    await this.$store.dispatch(ACTION_GOAL.UPDATE, {
      ...record,
      ordering: finalPosition,
    })
  }

  numberWithCommas(num) {
    return Formater.numberFormat(num)
  }

  async checkItemExists(goal: IGoal) {
    const { dispatch } = this.$store
    const params: IValidRequest = {
      app_id: this.appId,
      initial_id: '',
      push_id: '',
      card_id: goal._id ?? '',
      card_type: 'goal',
      total_messages: 0,
      campaign: {},
    }
    const resp = await dispatch(ACTION_APP.CHECK_ITEM_EXISTS, params)

    if (resp.campaign_title) {
      this.$q.dialog({
        title: this.$t('error'),
        message: this.$t('messages.item_exists_in_campaign', { names: resp.campaign_title.toString() }),
        persistent: true,
        html: true,
      })
      return true
    }
    return false
  }
}
</script>
